<template>
  <BaseContentLoader :title="title" :isLoading="isLoading">
    <template slot="content">
      <ExpertMode
        v-model="isExpertMode"
        :device="device"
        :integration="integration"
        :diagnosisData="this.getAllDiagnosis"
        :definedCommands="definedCommands"
        @refreshDiagnosisData="loadDiagnosis()"
        @sendDefinedCommand="(command) => sendDefinedCommand(command)"
        @sendCustomCommand="(command) => sendCustomCommand(command)"
        :disabled="disabledExpertMode"
      />

      <template v-if="!isExpertMode && newDevice !== undefined">
        <v-form v-model="isValid" ref="form" lazy-validation>
          <BaseSummary
            v-model="newDevice"
            :items="summaryItems"
            isEditable
            :customDetails="newDevice.customDetails"
          ></BaseSummary>
        </v-form>

        <v-row class="pt-8">
          <v-btn
            class="mx-3"
            color="primary"
            @click="exportTSD()"
            :disabled="disabledExportTSD"
            data-cy="btn-undo"
          >
            <v-icon left>mdi-export</v-icon>
            {{ $t("_ExportData") }}</v-btn
          >

          <v-spacer></v-spacer>
          <v-btn
            class="mx-3"
            @click="undoChanges()"
            :disabled="disabledUndo"
            data-cy="btn-undo"
          >
            <v-icon left>mdi-undo</v-icon>
            {{ $t("_UndoChanges") }}</v-btn
          >
          <v-btn
            class="mx-3"
            @click="saveChanges()"
            :disabled="disabledSave"
            color="primary"
            >{{ $t("_SaveChanges") }}</v-btn
          >
        </v-row>
      </template>
    </template>
  </BaseContentLoader>
</template>

<script>
import DevicesApi from "@/data/api/DevicesApi";
import {
  delay,
  isDifferent,
  locationToString,
  stringToLocation,
} from "@smart-city-plattform/vue-component-library/src/helper/helper";
import {
  locationRules,
  nameRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import ExpertMode from "@/components/ExpertMode.vue";

import moment from "moment";

export default {
  data() {
    return {
      isLoading: true,
      isValid: false,
      newDevice: undefined,
      isExpertMode: false,
    };
  },
  components: { ExpertMode },
  async mounted() {
    try {
      this.newDevice = cloneDeep(
        await this.$store.dispatch("loadDevice", this.$route.query.id),
      );
      this.newDevice.integration = cloneDeep(
        await this.$store.dispatch(
          "loadIntegration",
          this.newDevice.integrationId,
        ),
      );

      this.newDevice.lastSeenString = moment(this.newDevice.lastSeen).isValid()
        ? moment(this.newDevice.lastSeen).locale(this.getLocale).format("LLL")
        : "---";

      this.newDevice.locationString =
        this.newDevice.location !== undefined
          ? locationToString(this.newDevice.location)
          : "";

      await this.$store.dispatch(
        "loadAvailableDeviceCommands",
        this.newDevice.details,
      );
      await this.$store.dispatch("loadServices");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getIntegrationById",
      "getDeviceById",
      "getSupportedDeviceTypes",
      "isSystemAdmin",
      "isCurrentOrganizationsAdmin",
      "getAllDiagnosis",
      "getAvailableDeviceCommands",
      "getCurrentOrganization",
      "getServices",
      "getLocale",
    ]),
    title() {
      if (this.device === undefined) return;
      return this.$t("_Device") + ": " + this.device.name;
    },
    nameRules: () => nameRules(),
    locationRules: () => locationRules(),
    updateIntervalRules() {
      return [
        (v) => /^[0-9]*$/.test(v) || "Only numbers are allowed as input",
        (v) => v >= 60 || v == 0 || "Minimum 60 seconds",
      ];
    },
    vendors() {
      return [...new Set(this.getSupportedDeviceTypes.map((t) => t.vendor))];
    },
    deviceTypes() {
      var that = this;
      return [
        ...new Set(
          this.getSupportedDeviceTypes
            .filter((t) => t.vendor === that.device.details.vendor)
            .map((t) => t.deviceType),
        ),
      ];
    },
    firmwares() {
      var that = this;
      return [
        ...new Set(
          this.getSupportedDeviceTypes
            .filter(
              (t) =>
                t.vendor === that.device.details.vendor &&
                t.deviceType === that.device.details.deviceType,
            )
            .map((t) => t.firmware),
        ),
      ];
    },
    functions() {
      if (this.integration === undefined) return;

      if (this.integration.type === "REST") {
        return [{ value: "external", text: this.$t("_ExternalTrigger") }];
      } else {
        return [
          { value: "external", text: this.$t("_ExternalTrigger") },
          { value: "lighting", text: this.$t("_Light") },
          { value: "valve", text: "Valve" },
          { value: "light-segment", text: this.$t("_LightSegment") },
          { value: "environment", text: "Environment" },
          { value: "traffic", text: "Traffic" },
        ];
      }
    },
    device() {
      let device = this.getDeviceById(this.$route.query.id);

      if (device === undefined) return;
      if (device.location != null) {
        device.locationString = locationToString(device.location);
      } else {
        device.locationString = "---";
      }
      return device;
    },
    definedCommands() {
      return this.getAvailableDeviceCommands;
    },
    integrationType() {
      if (this.integration === undefined) return;
      return this.integration.type;
    },
    integration() {
      if (this.device === undefined) return;
      return this.getIntegrationById(this.device.integrationId);
    },
    services() {
      return this.getServices.filter(
        (s) => s.organizationId === this.getCurrentOrganization.id,
      );
    },
    disabledExpertMode() {
      if (this.device === undefined) return true;
      if (this.device.isEnabled === false) return true;
      return !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin);
    },
    disabledExportTSD() {
      if (!(this.isCurrentOrganizationsAdmin || this.isSystemAdmin))
        return true;
      if (this.device === undefined) return true;
      return !this.device.isTSD;
    },
    disabledSave() {
      if (!(this.isCurrentOrganizationsAdmin || this.isSystemAdmin))
        return true;
      if (this.newDevice === undefined) return true;
      if (!this.isValid) return true;

      var keys = this.summaryItems
        .map((si) => si.key)
        .filter(
          (i) =>
            i !== "integration.name" &&
            i !== "lastSeen" &&
            i !== "lastSeenString" &&
            i !== "location",
        );
      return !isDifferent(this.newDevice, this.device, keys);
    },
    disabledUndo() {
      if (!(this.isCurrentOrganizationsAdmin || this.isSystemAdmin))
        return true;
      if (this.newDevice === undefined) return true;

      var keys = this.summaryItems
        .map((si) => si.key)
        .filter(
          (i) =>
            i !== "integration.name" &&
            i !== "lastSeen" &&
            i !== "lastSeenString" &&
            i !== "location",
        );
      return !isDifferent(this.newDevice, this.device, keys);
    },
    summaryItems() {
      if (this.newDevice === undefined) return [];
      if (this.integration === undefined) return [];

      return [
        {
          key: "name",
          name: this.$t("_Name"),
          type: "text-field",
          props: {
            rules: this.nameRules,
            readonly: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
          },
        },
        {
          key: "description",
          name: this.$t("_Description"),
          type: "text-field",
          props: {
            readonly: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
          },
        },
        {
          key: "deviceId",
          name: this.$t("_DeviceId"),
          type: "text-field",
          props: {
            readonly: true,
          },
        },
        {
          key: "locationString",
          name: this.$t("_Location"),
          type: "text-field",
          props: {
            readonly: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
            rules: this.locationRules,
          },
        },
        {
          key: "details.vendor",
          name: this.$t("_Vendor"),
          type: "select",
          props: { items: this.vendors, readonly: true },
        },
        {
          key: "details.deviceType",
          name: this.$t("_DeviceType"),
          type: "select",
          props: { items: this.deviceTypes, readonly: true },
        },
        {
          key: "details.firmware",
          name: this.$t("_Firmware"),
          type: "select",
          props: {
            items: this.firmwares,
            readonly: !(this.isCurrentOrganizationsAdmin || this.isSystemAdmin),
          },
        },
        {
          key: "tag",
          name: this.$t("_Tag"),
          type: "select",
          props: { items: this.functions, disabled: true },
        },

        {
          key: "integration.name",
          name: this.$t("_Integration"),
          type: "text-field",
          props: {
            disabled: true,
          },
        },
        {
          key: "mapping",
          name: this.$t("_Service"),
          type: "select",
          props: {
            items: this.services,
            "item-text": "name",
            "item-value": "id",
            readonly: !(this.isSystemAdmin || this.isCurrentOrganizationsAdmin),
            disabled: this.device.mapping !== undefined,
          },
        },
        {
          key: "isTSD",
          name: this.$t("_IsTSD"),
          type: "checkbox",
          props: {
            disabled: !this.isSystemAdmin,
          },
        },
        {
          key: "lastSeenString",
          name: this.$t("_LastSeen"),
          type: "text-field",
          props: {
            readonly: true,
          },
        },
        // MPDT210068-3444: Add a textfield "Update Interval (minutes)"
        // The expected interval in minutes in which the device sends uplink messages.
        // This is used to determine if a device is active or inactive
        // "0":  Don't take care
        // ">0": Should send warnings if lastSeen - now >= value
        {
          key: "updateInterval",
          name: this.$t("_UpdateInterval"),
          type: "text-field",
          props: {
            readonly: !(this.isSystemAdmin || this.isCurrentOrganizationsAdmin),
            rules: this.updateIntervalRules,
            type: "number",
          },
        },
      ];
    },
  },
  methods: {
    undoChanges() {
      this.newDevice = cloneDeep(this.device);
      this.newDevice.integration = cloneDeep(this.integration);

      this.newDevice.lastSeenString = moment(this.newDevice.lastSeen).isValid()
        ? moment(this.newDevice.lastSeen).locale(this.getLocale).format("LLL")
        : "---";

      this.newDevice.locationString =
        this.newDevice.location !== undefined
          ? locationToString(this.newDevice.location)
          : "";

      this.$refs.form.resetValidation();
    },
    async saveChanges() {
      this.isLoading = true;
      try {
        const payload = {
          id: this.device.id,
          name: this.newDevice.name,
          description: this.newDevice.description,
          integrationId: this.device.integrationId,
          organizationId: this.device.organizationId,
          details: this.newDevice.details,
          location:
            this.newDevice.locationString !== "" &&
            this.newDevice.locationString !== undefined
              ? stringToLocation(this.newDevice.locationString)
              : undefined,
          deviceId: this.device.deviceId,
          mapping: this.newDevice.mapping,
          tag: this.newDevice.tag,
          isTSD: this.newDevice.isTSD,
          updateInterval: this.newDevice.updateInterval,
        };
        await delay(500);
        await this.$store.dispatch("updateDevice", payload);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async sendDefinedCommand(command) {
      await DevicesApi.sendDeviceCommand(this.device, command);
    },
    async sendCustomCommand(command) {
      await DevicesApi.sendDeviceMessage(this.device, command);
    },
    async exportTSD() {
      await this.$router.push(
        "/dad/devices/device/export?id=" + this.$route.query.id,
      );
    },
    async loadDiagnosis() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("loadDiagnosis", this.device.id);
      } catch (error) {
        console.log(error);
      }

      this.isLoading = false;
    },
  },
  watch: {
    isExpertMode: {
      handler: function () {
        if (this.isExpertMode === true) {
          this.loadDiagnosis();
        }
      },
    },
  },
};
</script>
