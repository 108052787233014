<template>
  <BaseContentLoader :isLoading="isLoading">
    <template slot="content">
      <BaseTabDialog
        :subHeaders="subHeaders"
        :tabHeaders="tabHeaders"
        @confirm="onboardDevices()"
        @cancel="$router.push('/dad/devices')"
        @forward="(tab) => handleForward(tab)"
      >
        <template slot="tab-item-0">
          <v-col cols="6">
            <v-select
              :label="$t('_Integration')"
              v-model="integration"
              item-text="name"
              :items="integrations"
              :rules="integrationRules"
              return-object
              data-cy="tf-name"
            ></v-select>
          </v-col>
        </template>

        <template slot="tab-item-1" v-if="integration !== undefined">
          <template
            v-if="
              integration.type === 'REST' ||
              integration.type === 'WEBHOOK_DIGIMONDO'
            "
          >
            <v-col cols="6">
              <v-text-field
                class="pt-8"
                v-model="name"
                :label="$t('_Name') + ' *'"
                data-cy="tf-name"
                :rules="nameRules"
              ></v-text-field>
            </v-col>
          </template>
          <template v-else>
            <v-col>
              <BaseTable
                v-model="selectedDevices"
                :headers="deviceHeaders"
                :items="availableDevices"
                :loading="isLoadingTable"
                item-key="deviceId"
                :rules="tableRules"
                show-select
              />
            </v-col>
          </template>
        </template>
        <template slot="tab-item-2">
          <v-col cols="5">
            <v-select
              v-model="details.vendor"
              :label="$t('_Vendor') + ' *'"
              :items="vendors.sort()"
              :rules="vendorRules"
              class="pt-8"
              data-cy="sel-vendor"
            ></v-select>
            <v-select
              v-model="details.deviceType"
              :label="$t('_DeviceType') + ' *'"
              :items="deviceTypes.sort()"
              :rules="deviceTypeRules"
              data-cy="sel-device-type"
            ></v-select>
            <v-select
              v-model="details.firmware"
              :label="$t('_Firmware') + ' *'"
              :items="firmwares"
              :rules="firmwareRules"
              data-cy="sel-firmware"
            ></v-select>
          </v-col>
          <v-col cols="5">
            <v-select
              v-model="tag"
              :items="functions"
              :label="$t('_Function') + ' *'"
              item-text="text"
              item-value="value"
              data-cy="sel-funtion"
              :rules="functionRules"
            />
          </v-col>
        </template>

        <template slot="tab-item-3">
          <v-col cols="5">
            <v-autocomplete
              v-model="service"
              :items="services"
              :label="$t('_Service')"
              prependInnerIcon="mdi-toolbox-outline"
              item-text="name"
              color="primary"
              return-object
              clearable
            />
          </v-col>
        </template>
      </BaseTabDialog>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";
import {
  integrationRules,
  tableRules,
  nameRules,
  vendorRules,
  deviceTypeRules,
  firmwareRules,
  functionRules,
} from "@smart-city-plattform/vue-component-library/src/helper/rules";
import DevicesApi from "@/data/api/DevicesApi";

export default {
  data() {
    return {
      isLoading: true,
      isLoadingTable: false,
      integration: undefined,
      service: undefined,
      availableDevices: [],
      selectedDevices: [],
      name: "",
      details: {},
      tag: "",
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadIntegrations");
      await this.$store.dispatch("loadServices");
      await this.$store.dispatch("loadDevices");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getOrganizations",
      "getCurrentOrganization",
      "getIntegrationsByOrganizationId",
      "getServicesByOrganizationId",
      "getAllDevices",
      "getServices",
      "isCurrentOrganizationsAdmin",
      "getSupportedDeviceTypes",
    ]),
    subHeaders() {
      return [
        `${this.$t("__CFA1_OnboardDevices")}`,
        this.integration?.type === "REST" ||
        this.integration?.type === "WEBHOOK_DIGIMONDO"
          ? `${this.$t("__CFA2a_OnboardDevices")} ${
              this.integration?.name ? ` (${this.integration.name})` : ""
            }`
          : `${this.$t("__CFA2b_OnboardDevices")} ${
              this.integration?.name ? ` (${this.integration.name})` : ""
            }`,
        `${this.$t("__CFA3_OnboardDevices")} ${
          this.integration?.name ? ` (${this.integration.name})` : ""
        }`,
        `${this.$t("__CFA3_OnboardDevices")} ${
          this.integration?.name ? ` (${this.integration.name})` : ""
        }`,
      ];
    },
    tabHeaders() {
      return [
        this.$t("_SelectIntegration"),
        this.$t("_SelectDevices"),
        this.$t("_DeviceType") + " & " + this.$t("_Function"),
        this.$t("_Service"),
      ];
    },
    integrations() {
      return this.getIntegrationsByOrganizationId(
        this.getCurrentOrganization.id,
      );
    },
    allDeviceIds() {
      return this.getAllDevices.map((d) => d.deviceId);
    },
    tableRules() {
      return tableRules();
    },
    integrationRules() {
      return integrationRules();
    },
    nameRules() {
      return nameRules();
    },
    vendorRules() {
      return vendorRules();
    },
    deviceTypeRules() {
      return deviceTypeRules();
    },
    firmwareRules() {
      return firmwareRules();
    },
    functionRules() {
      return functionRules();
    },
    vendors() {
      return [...new Set(this.getSupportedDeviceTypes.map((t) => t.vendor))];
    },
    deviceTypes() {
      let that = this;
      return [
        ...new Set(
          this.getSupportedDeviceTypes
            .filter((t) => t.vendor === that.details.vendor)
            .map((t) => t.deviceType),
        ),
      ];
    },
    firmwares() {
      let that = this;
      return [
        ...new Set(
          this.getSupportedDeviceTypes
            .filter(
              (t) =>
                t.vendor === that.details.vendor &&
                t.deviceType === that.details.deviceType,
            )
            .map((t) => t.firmware),
        ),
      ];
    },
    functions() {
      if (this.integration === undefined) return;

      if (
        this.integration.type === "REST" ||
        this.integration.type === "WEBHOOK_DIGIMONDO"
      ) {
        return [
          { value: "external", text: this.$t("_ExternalTrigger") },
          { value: "environment", text: "Environment" },
        ];
      } else {
        return [
          { value: "external", text: this.$t("_ExternalTrigger") },
          { value: "lighting", text: this.$t("_Light") },
          { value: "light-segment", text: this.$t("_LightSegment") },
          { value: "valve", text: "Valve" },
          { value: "environment", text: "Environment" },
          { value: "traffic", text: "Traffic" },
        ];
      }
    },
    deviceHeaders() {
      return [
        { text: this.$t("_Name").toUpperCase(), value: "name" },
        { text: this.$t("_DeviceId").toUpperCase(), value: "deviceId" },
      ];
    },
    services() {
      return this.getServicesByOrganizationId(this.getCurrentOrganization.id);
    },
  },
  methods: {
    async onboardDevices() {
      let deviceBulk = {};

      if (
        this.integration.type === "REST" ||
        this.integration.type === "WEBHOOK_DIGIMONDO"
      ) {
        let device = {
          name: this.name,
          tag: this.tag,
          integrationId: this.integration.id,
          mapping: this.service !== undefined ? this.service.id : null,
          organizationId: this.getCurrentOrganization.id,
          deviceId: this.uniqueId(),
          details: this.details,
        };
        deviceBulk = {
          deviceList: [device],
        };
      } else {
        deviceBulk = {
          deviceList: this.selectedDevices.map((dev) => {
            let obj = Object.assign({}, dev);
            obj.id = null;
            obj.tag = this.tag;
            obj.integrationId = this.integration.id;
            obj.mapping = this.service !== undefined ? this.service.id : null;
            obj.organizationId = this.getCurrentOrganization.id;
            obj.details = this.details;
            return obj;
          }),
        };
      }

      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch("onboardDevices", deviceBulk);
        await this.$router.push("/dad/devices");
      } catch (error) {
        console.log(error);
      }

      this.isLoading = false;
    },
    uniqueId() {
      return Date.now().toString(36) + Math.random().toString(36);
    },
    async handleForward(tab) {
      let that = this;
      switch (tab) {
        case 1:
          if (this.integration === undefined) return;
          if (this.integration.type === "REST") return;
          if (this.integration.type === "WEBHOOK_DIGIMONDO") return;

          this.isLoadingTable = true;
          try {
            this.availableDevices = await DevicesApi.listAvailableDevices(
              this.integration.id,
            ).then((r) => r.data);
            this.availableDevices = this.availableDevices.filter(
              (device) => !that.allDeviceIds.includes(device.deviceId),
            );
          } catch (error) {
            console.log(error);
          }
          this.isLoadingTable = false;

          break;

        default:
          break;
      }
    },
  },
};
</script>

<style></style>
